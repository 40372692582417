
import {
  Vue, Component,
} from 'vue-property-decorator';
import { getAlarmCount, getAlarmList } from '@/api/pushAlarm';

@Component({
  name: 'AppHeader',
})
export default class extends Vue {
    private handleClickBefore() {
    if (this.$route.name === 'Agree') {
      this.$router.push({ name: 'Login' });
    } else if (this.$route.name === 'Signup') {
      this.$router.push({ name: 'Agree' });
    } else if (this.$route.name === 'Info') {
      this.$router.push({ name: 'Signup' });
    } else {
      this.$router.go(-1);
    }
  }
}
